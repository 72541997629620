import { Override } from "framer"
import { motion, useMotionValue, useSpring } from "framer-motion"
import { useEffect, useRef } from "react"

// Code override provided by Eleveight Supply. All rights reserved 2024.

export function Followcursor({
    damping = 100, // Adjusts the "spring" effect (lower means more bouncy) Recommended value: 100
    stiffness = 1000, // Adjusts how stiff the animation happens (lower is stiffer) Recommended value: 1000
    effectRange = 2, // Adjusts the movement range (higher means the element will move further)
    intensity = 5, // Adjusts the intensity to make the movement more or less noticeable within the range
    actionableArea = 30, // Adjusts the area in which the cursor enters or leaves where the hover effect will happen
}: {
    damping?: number
    stiffness?: number
    effectRange?: number
    intensity?: number
    actionableArea?: number
}): Override {
    const x = useMotionValue(0)
    const y = useMotionValue(0)
    const springX = useSpring(x, { damping, stiffness })
    const springY = useSpring(y, { damping, stiffness })
    const ref = useRef(null)

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (!ref.current) return
            const element = ref.current as HTMLElement
            const rect = element.getBoundingClientRect()
            const mouseX = event.clientX
            const mouseY = event.clientY
            const isWithinBounds =
                mouseX >= rect.left - actionableArea &&
                mouseX <= rect.right + actionableArea &&
                mouseY >= rect.top - actionableArea &&
                mouseY <= rect.bottom + actionableArea
            if (isWithinBounds) {
                const displacementX = mouseX - (rect.left + rect.width / 2)
                const displacementY = mouseY - (rect.top + rect.height / 2)
                const normalizedX =
                    (displacementX / (rect.width / 2)) * effectRange * intensity
                const normalizedY =
                    (displacementY / (rect.height / 2)) *
                    effectRange *
                    intensity

                x.set(normalizedX)
                y.set(normalizedY)
            } else {
                x.set(0)
                y.set(0)
            }
        }
        window.addEventListener("mousemove", handleMouseMove)
        return () => {
            window.removeEventListener("mousemove", handleMouseMove)
        }
    }, [effectRange, intensity, actionableArea, x, y, damping, stiffness])
    return {
        ref: ref,
        style: {
            x: springX,
            y: springY,
        },
    }
}
